<template>
  <div v-if="Object.keys(admin_scenario_accessory).length !== 0">
    <h5>Accessory details of  <span style="font-weight: 600">{{ admin_scenario_accessory.name }}</span></h5>
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body">
          <div class="row">
              <div class="col-md-3">
                <div class="card bg-danger">
                  <div class="card-body position-relative overflow-hidden">
                    <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ issue }}</div>
                    <div class="text-uppercase">Issue - Raised Issue</div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card bg-warning">
                  <div class="card-body position-relative overflow-hidden">
                    <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ pending }}</div>
                    <div class="text-uppercase">Issue Pending</div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                  <div class="card bg-warning">
                    <div class="card-body position-relative overflow-hidden">
                      <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ inprogress }}</div>
                      <div class="text-uppercase">Issue in Progress</div>
                    </div>
                  </div>
                </div>
              <div class="col-md-3">
                <div class="card bg-success">
                  <div class="card-body position-relative overflow-hidden">
                    <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ solved }}</div>
                    <div class="text-uppercase">Issue Solved</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </a-spin>
    </div>
    <a-skeleton active :loading="loading"></a-skeleton>
    <h5><a @click="$router.go(-1)" class="btn btn-danger"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a></h5>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'update',
  components: { },
  data() {
    return {
      issue: 0,
      pending: 0,
      solved: 0,
      inprogress: 0,
      admin_scenario_accessory: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      search_data: {
        admin_scenario_accessory_id: '',
      },
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.spinning = true
      this.loading = true
      this.search_data.admin_scenario_accessory_id = this.$route.params.admin_scenario_accessory_id
      apiClient.post('api/admin-scenario/issue/accessory/details/search', this.search_data)
        .then(response => {
          this.spinning = false
          this.loading = false
          if (response.data.error === true) {
            this.$notification.error({
              message: response.data.message,
            })
          } else {
            this.issue = response.data.issue
            this.pending = response.data.pending
            this.solved = response.data.solved
            this.inprogress = response.data.inprogress
            this.admin_scenario_accessory = response.data.admin_scenario_accessory
          }
        })
        .catch(error => {
          this.spinning = false
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
